$yakiri-green: #5e9633;
$yagiri-brown: #694731;

.blogMain {
  padding: 0 2em 1em;
  * {
    word-break: break-all;
  }
  p {
    line-height: 1.75em;
    margin-top: 0.75em;
  }
  h3,
  h4 {
    line-height: 1.4em;
    color: $yakiri-green;
    // &::before {
    //   content: "";
    //   width: 5px;
    //   height: 100%;
    //   background: $yagiri-brown;
    //   display: block;
    // }
  }
  h3 {
    font-size: 1.4em;
  }
  h4 {
    font-size: 1.2em;
  }
  a:not([href^="tel:"]):not([href^="mailto:"]):not(.baseLink) {
    display: inline-block;
    line-height: 1em;
    margin-top: 0.75em;
    padding: 0.5em;
    background-color: $yakiri-green;
    color: white;
  }
  a[href^="tel:"],
  a[href^="mailto:"],
  .baseLink {
    font-size: 1em;
    line-height: inherit;
    color: #5e9633;
    text-decoration: underline;
  }
  ul {
    list-style: disc !important;
  }
  ol {
    list-style: decimal !important;
  }
  ul,
  ol {
    padding-left: 1em !important;
  }
  li {
    margin-top: 10px !important;
  }
}
